import React from "react"

import { Unlockable } from "../../services/unlockables"

interface Props {
  unlockable: Unlockable
}

export default function UnlockableDisplay(props: Props) {
  const { description, name } = props.unlockable

  return (
    <div>
      <h6 className="bold text-s text--white text-uppercase">{name}</h6>

      <p style={{ maxWidth: "400px" }} className="mx-auto text-center text--white text-l mb-0">
        {description}
      </p>
    </div>
  )
}
