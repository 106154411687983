import React, { useState } from "react"
import last from "lodash/last"
import { Progress } from "reactstrap"
import { useTransition, useSpring, useChain, config, animated, useSpringRef } from "@react-spring/web"

import { IntermissionData, Medal, Statistic, StatisticDirection, StatisticName } from "../../services/intermission"

// @ts-ignore
import goldMedal from "../../lib/images/gold-medal.svg"
// @ts-ignore
import silverMedal from "../../lib/images/silver-medal.svg"
// @ts-ignore
import bronzeMedal from "../../lib/images/bronze-medal.svg"
// @ts-ignore
import grayMedal from "../../lib/images/gray-medal.svg"

interface Props {
  data: IntermissionData
}

export default function ProgressDisplay(props: Props) {
  const { statistics, nextRank, currentRank, currentStars, stars } = props.data

  const [rankProgress, setRankProgress] = useState((100 * (currentStars - currentRank.min)) / ((nextRank?.min || 0) - currentRank.min))
  const [displayStars, setDisplayStars] = useState(false)

  const springApi = useSpringRef()

  const { size, ...rest } = useSpring({
    ref: springApi,
    config: config.slow,
    from: { size: "100%" },
    to: { size: "100%" },
  })

  const transApi = useSpringRef()

  const filteredStatistics = statistics.filter((s) => s.name === StatisticName.Accuracy || s.value)

  const transition = useTransition(filteredStatistics, {
    ref: transApi,
    trail: 1200 / filteredStatistics.length,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
    onRest: (_: any, __: any, y: Statistic) => {
      const isDone = y.name === last(filteredStatistics)?.name
      if (!isDone) return

      setRankProgress((100 * (currentStars + stars - currentRank.min)) / ((nextRank?.min || 0) - currentRank.min))
      setDisplayStars(true)
    },
  })

  useChain([springApi, transApi], [0, 0.5])

  return (
    <div style={{ maxWidth: "1100px" }} className="w-100 h-100 d-flex flex-column justify-content-center">
      <div className="flex-center">
        <animated.div
          style={{
            ...rest,
            width: size,
            height: "fit-content",
            display: "grid",
            gridTemplateColumns: `repeat(${filteredStatistics.length}, minmax(100px, 225px))`,
            willChange: "width, height",
          }}
          className="position-relative py-4 justify-content-center"
        >
          {transition((style: any, statistic: Statistic) => {
            const isIncrease = statistic.direction === StatisticDirection.Increase
            const isDecrease = statistic.direction === StatisticDirection.Decrease
            const color = isIncrease ? "success" : "gray6"
            const medal =
              statistic.medal === Medal.Gold
                ? goldMedal
                : statistic.medal === Medal.Silver
                ? silverMedal
                : statistic.medal === Medal.Bronze
                ? bronzeMedal
                : grayMedal

            return (
              <animated.div
                key={statistic.name}
                className="statistic bg--white p-3 m-3 border rounded d-flex flex-column align-items-center justify-content-center h-100"
                style={{ ...style, willChange: "width, height", boxShadow: "0px 10px 10px -5px rgba(0, 0, 0, 0.2)" }}
              >
                <img style={{ width: "100%", maxWidth: "80px", height: "auto" }} src={medal} />

                <div className="position-relative">
                  <h3 className={`bold text-xxxl text--${color} mb-2`}>{statistic.value}</h3>

                  <div
                    style={{ top: "8px", right: "-18px" }}
                    className={`position-absolute arrow ${isIncrease ? "arrow-up" : isDecrease ? "arrow-down" : ""}`}
                  />
                </div>

                <p style={{ marginTop: "-10px" }} className="bold gray7 mb-0 text-s text-uppercase">
                  {statistic.name}
                </p>

                <p style={{ opacity: statistic.careerName ? 1 : 0 }} className="bold gray5 text-xxs m-0">
                  {statistic.careerName}: {statistic.careerValue}
                </p>
              </animated.div>
            )
          })}
        </animated.div>
      </div>

      {nextRank && (
        <div className="flex-center flex-column w-100 mt-4 px-3">
          <div id="rank-progress" className="d-flex justify-content-between align-items-end w-100">
            <p className="text-left flex-even bold italic text-xl text-capitalize m-0 text--white">{currentRank.name}</p>

            <p className={`${displayStars ? "" : "opacity-0"} bold italic transition-m text-uppercase mb-0 text-xxl text--white`}>+ {stars} stars</p>

            <p className="text-right flex-even bold italic text-xl text-capitalize m-0 text--white">{nextRank.name}</p>
          </div>

          <Progress color="secondary" className="w-100 progress-slow" value={rankProgress} />

          <div className="d-flex justify-content-end w-100">
            <p className="semibold italic text-uppercase m-0 text--white">
              {currentStars + stars} / {nextRank.min}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
