// @ts-ignore
import * as d3 from "d3"
import sample from "lodash/sample"
import React, { useEffect } from "react"
import cloneDeep from "lodash/cloneDeep"

import { Node, ForceNetworkData } from "../../hasura/slices/curriculum"
import { colors } from "./colors"

// http://jsfiddle.net/3SWtU/
// https://observablehq.com/@xianwu/simple-force-directed-graph-network-graph

interface Props {
  forceNetwork?: ForceNetworkData
}

export default function MindMap(props: Props) {
  useEffect(() => {
    if (!props.forceNetwork) return

    setTimeout(() => {
      const element = document.querySelector("#mind-map")
      if (!element) return

      // @ts-ignore
      const { offsetWidth, offsetHeight } = element

      const svg = d3
        .select("#mind-map")
        .append("svg")
        .attr("id", "mind-map-svg")
        .attr("width", offsetWidth)
        .attr("height", offsetHeight)
        .append("g")
        .attr("id", "everything")

      const dataset = cloneDeep(props.forceNetwork!)

      const radius = 35
      const linkDistance = 150
      const charge = -70
      const forceCollideRadius = radius * 1.25

      const simulation = d3
        .forceSimulation()
        .force(
          "link",
          d3
            .forceLink()
            .id((d: any) => d.id)
            .distance(linkDistance)
        )
        .force("charge", d3.forceManyBody().strength(charge))
        .force("collide", d3.forceCollide(forceCollideRadius))
        .force("center", d3.forceCenter(offsetWidth / 2, offsetHeight / 2))

      const link = svg.append("g").attr("class", "links").selectAll("line").data(dataset.links).enter().append("line").attr("class", "link")

      const node = svg
        .append("g")
        .selectAll("circle")
        .data(dataset.nodes)
        .enter()
        .append("circle")
        .attr("r", radius)
        .attr("class", (d: any) => (d.isRoot ? "root-nodes" : "concept-nodes"))
        .attr("fill", (d: Node) => {
          if (!d.isRoot) return

          const accuracy = d.stats?.accuracy
          // TODO: - don't pick used color
          if (!accuracy) return sample(colors)

          if (accuracy > 85) {
            return "#228000"
          } else if (accuracy > 70) {
            return "#4b62db"
          } else {
            return "#D1273F"
          }
        })
        .on("mouseover", highlight)
        .on("mouseout", highlight)

      const text = svg
        .selectAll("text")
        .data(dataset.nodes)
        .enter()
        .append("text")
        .attr("class", (d: any) => (d.isRoot ? "root-text noselect" : "concept-text noselect"))
        .text((d: any) => d.id)
        .attr("dy", "0.3em")
        .style("pointer-events", "none")

      function ticked() {
        link
          .attr("x1", (d: any) => d.source.x)
          .attr("y1", (d: any) => d.source.y)
          .attr("x2", (d: any) => d.target.x)
          .attr("y2", (d: any) => d.target.y)

        node.attr("cx", (d: any) => d.x).attr("cy", (d: any) => d.y)

        text.attr("x", (d: any) => d.x).attr("y", (d: any) => d.y)
      }

      simulation.nodes(dataset.nodes).on("tick", ticked)
      simulation.force("link").links(dataset.links)

      simulation.tick(300)

      dataset.nodes.forEach((node) => {
        // @ts-ignore
        node.associatedLinks = dataset.links.filter((link) => link.source == node || link.target == node)
      })

      let lastHighlightedNode: any = null

      d3.select("body").on("mousemove", function () {
        if (lastHighlightedNode) highlight(lastHighlightedNode)
      })

      function highlight(d: any) {
        if (!d.target) return

        const type = event?.type
        const entered = type === "mouseover"
        const exited = type === "mouseout"

        lastHighlightedNode = d.target.__data__

        if (entered) {
          lastHighlightedNode.highlight = 1

          lastHighlightedNode.associatedLinks.forEach((link: any) => {
            link.highlight = 1
            link.source.highlight = 1
            link.target.highlight = 1
          })
        } else if (exited) {
          lastHighlightedNode.highlight = 0

          lastHighlightedNode.associatedLinks.forEach((link: any) => {
            link.highlight = 0
            link.source.highlight = 0
            link.target.highlight = 0
          })
        }

        svg.selectAll(".root-nodes").style("opacity", (d: any) => (d?.highlight === 1 || exited ? 1 : 0.1))
        svg.selectAll(".concept-text").style("opacity", (d: any) => (d?.highlight === 1 || exited ? 1 : 0.1))
        svg.selectAll(".links line").style("opacity", (d: any) => (d?.highlight === 1 || exited ? 1 : 0.1))
        svg.selectAll(".concept-nodes").style("opacity", (d: any) => (d?.highlight === 1 || exited ? 1 : 0.1))
        svg.selectAll(".link").attr("stroke", (d: any) => (d?.highlight === 1 || exited ? "#4b62db" : "#afb5ff"))
      }
    }, 1000)
  }, [props.forceNetwork])

  return <div style={{ overflow: "scroll" }} className="w-100 h-100" id="mind-map" />
}
