export const colors = [
  "#f8dc7c",
  "#f4eb7f",
  "#d3e386",
  "#aae8a7",
  "#91f4ce",
  "#ffba75",
  "#ffab76",
  "#ff9e88",
  "#fe9a99",
  "#ff93bd",
  "#a4f2f2",
  "#a4e3ff",
  "#9fceff",
  "#8ebeff",
  "#79b2ff",
  "#d39aff",
  "#b49dfe",
  "#a3a7ff",
  "#8b98ff",
  "#7b8bff",
]
