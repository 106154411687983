import React, { useEffect, useState } from "react"
import { Button } from "reactstrap"
import { navigate } from "gatsby-link"
import { useDispatch, useSelector } from "react-redux"

import CONFIG from "../config"
import Layout from "../components/layout"
import MindMap from "../components/mindMap"
import ProgressDisplay from "../components/intermission/progress"
import SEO from "../components/seo"
import UnlockableDisplay from "../components/intermission/unlockable"
import Unlockables, { UnlockableName } from "../services/unlockables"
import { QueryName } from "../hasura/queryNames"
import { UserEvent } from "../lib/userEventTypes"
import { insertUserEventAction, userSelector, UserState } from "../hasura/slices/user"
import { shootConfetti, withCommas } from "../lib/helpers"
import { unsetQuestionsAction } from "../hasura/slices/sequence"

import {
  curriculumSelector,
  CurriculumState,
  fetchClassroomExperienceAction,
  ForceNetworkData,
  forceNetworkForConcepts,
  setIntermissionDataAction,
} from "../hasura/slices/curriculum"

// @ts-ignore
import exitIcon from "../lib/images/exit-white.svg"
// @ts-ignore
import partyIcon from "../lib/images/party.png"

enum IntermissionDisplay {
  Progress,
  Unlockable,
  MindMap,
}

export default function IntermissionPage() {
  const dispatch = useDispatch()

  const { accessToken, user, isQuerying }: UserState = useSelector(userSelector)
  const { classroomExperience, intermissionData }: CurriculumState = useSelector(curriculumSelector)

  const [display, setDisplay] = useState<IntermissionDisplay>(
    intermissionData?.unlocked ? IntermissionDisplay.Unlockable : IntermissionDisplay.Progress
  )
  const [forceNetwork, setForceNetwork] = useState<ForceNetworkData | undefined>()

  useEffect(() => {
    dispatch(unsetQuestionsAction())
    dispatch(fetchClassroomExperienceAction("", 1))

    return () => {
      dispatch(setIntermissionDataAction())
    }
  }, [])

  useEffect(() => {
    if (!user || !accessToken) return

    const event = { user_id: user.id, user_event_type_id: UserEvent.FinishedLevel }
    dispatch(insertUserEventAction(accessToken, event))
  }, [user, accessToken])

  useEffect(() => {
    if (display === IntermissionDisplay.Unlockable) shootConfetti()
  }, [display])

  useEffect(() => {
    if (!classroomExperience || !intermissionData) return

    // TODO: - different query
    setForceNetwork(
      forceNetworkForConcepts(
        classroomExperience,
        intermissionData.conceptProgress.map((p) => p.concept)
      )
    )
  }, [classroomExperience, intermissionData])

  const handleNext = () => {
    if (display === IntermissionDisplay.Unlockable) {
      setDisplay(IntermissionDisplay.Progress)
    } else if (display === IntermissionDisplay.Progress) {
      if (Unlockables.isUnlocked(UnlockableName.MindMap, user)) {
        setDisplay(IntermissionDisplay.MindMap)
      } else {
        nextLevel()
      }
    } else if (display === IntermissionDisplay.MindMap) {
      nextLevel()
    }
  }

  const nextLevel = () => navigate(`/play?type=core${CONFIG.IS_DEVELOPMENT ? "&test=true" : ""}`)

  const title = (() => {
    if (!intermissionData) return

    switch (display) {
      case IntermissionDisplay.Unlockable:
        return `${intermissionData.unlocked?.name} Unlocked!`
      case IntermissionDisplay.Progress:
        return `Level ${withCommas(intermissionData.level)} Complete!`
      default:
        return
    }
  })()

  return (
    <Layout noHeader noStyles>
      <SEO title="Intermission" />

      <div
        id="intermission-container"
        className={`
          primary-gradient-reverse text-center flex-center d-flex flex-column 
          ${display === IntermissionDisplay.Progress ? "min-vw-100 min-vh-100" : "vw-100 vh-100 position-fixed"}
        `}
      >
        <img onClick={() => navigate("/home")} style={{ top: "20px", left: "20px" }} className="position-fixed svg-icon large" src={exitIcon} />

        <h1 className="bold m-0 text-xxl text--white mt-4">{title}</h1>

        {intermissionData && (
          <div className="w-100 flex-center" style={{ flexGrow: 1 }}>
            {display === IntermissionDisplay.Unlockable && <UnlockableDisplay unlockable={intermissionData.unlocked!} />}
            {display === IntermissionDisplay.Progress && <ProgressDisplay data={intermissionData} />}
            {display === IntermissionDisplay.MindMap && <MindMap forceNetwork={forceNetwork} />}
          </div>
        )}

        <Button
          style={{ opacity: isQuerying[QueryName.CompletedLevel] ? 0 : 1 }}
          onClick={handleNext}
          size="lg"
          className="bg-white border-primary text-primary min-width-300px mb-3 transition-m"
        >
          {display === IntermissionDisplay.MindMap ? "Next Round" : "Next"}
        </Button>
      </div>
    </Layout>
  )
}
